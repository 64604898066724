import React from "react"
import FotoEnseña from "@components/pageCasosdeExito/image/imgPhotoEmpresa/royal/FotoRoyalEnfield.png"
import FotoEnseña2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/royal/FotoRoyalEnfield2.png"

//logo de empresas clientes
import RoyalEnfield from "@components/pageCasosdeExito/image/logoscustomer/RoyalEnfield.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"
import multicanal from "@components/pageCasosdeExito/image/iconcards/multicanal.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/royalEnfield/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/royalEnfield/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/royalEnfield/img3.svg"

const text1 = (
  <span className="">
    Royal Enfield es una marca multinacional de motocicletas con presencia a
    nivel mundial. Tiene más de 130 años de historia y, actualmente, con
    fabricación en la India, la marca brinda motos de estilos retro, cargadas
    con tecnología moderna.
    <br /> <br />
    La marca es capaz de administrar la comunicación omnicanal de sus
    franquicias y tiendas gracias a la gestión multicuentas de Beex. Además,
    ha logrado aumentar en 27% la cantidad de leads bien prospectados por
    digital.
  </span>
)

const text2 = (
  <span>
    Royal Enfield, representada en Perú por Advance Sport, es una marca de culto
    en motocicletas reconocida a nivel mundial. Creada en 1901, la marca de
    origen británico y que actualmente se fabrica en la India, tiene presencia
    en diferentes países lationamericanos, como Perú, Colombia, Argentina y
    México.
    <br /> <br />
    Liliana López, Jefe de Marketing en Royal Enfield, ha gestionado y
    acompañado en la implementación de Beex, siendo uno de los puntos principales el alta de las cuentas de
    WhatsApp Business API para todas sus tiendas, así como la integración con
    sus sistemas internos.
    <br /> <br />
    Teniendo en cuenta esto, el equipo de Customer Success de Beex ayudó en la
    verificación de las cuentas de Facebook Business para acceder al API de
    WhatsApp, poniendo en producción los números de atención y ventas en tiempo
    record.
    <br /> <br />
    Y no solo el canal de WhatsApp era necesario, sino, se añadieron e
    integraron canales digitales como Facebook Messenger e Instagram. Para el
    área de marketing y ventas, esto ha sido algo de importancia porque les
    permite tener una vista 360 de sus leads y clientes.
    <br /> <br />
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Beex está ayudando a nuestra empresa en tener una experiencia única en
    atención. Dejamos de gestionar en diferentes plataformas y ahora incluso
    podemos supervisar toda nuestra operación, como el rendimiento de nuestra
    fuerza de ventas.”
  </p>
)

const text4 = (
  <span>
    Con estas acciones, Royal Enfield empezó a mejorar sus principales métricas
    de gestión y, sobre todo, automatizo muchas acciones que anteriormente era
    muy manuales, como la atención a preguntas frecuentes, prospecciones de los
    leads digitales y el agendar citas a sus tiendas para conocer sus
    motocicletas.
  </span>
)

const text5 = (
  <span>
    Como marca reconocida, Royal Enfield maneja diferentes tiendas que cuentan
    con canales únicos de contacto o compartidos entre estas. Para este caso, se
    dieron de alta diferentes APIs de WhatsApp Business (por tienda) y añadieron
    canales compartidos de Messenger e Instagram.
    <br /> <br />
    Algo de mucha ayuda para esta necesidad, es la opción de subcuentas que se
    manejan en Beex. Con esto, Royal Enfield ha logrado administrar sus
    tiendas y franquicias bajo un esquema de owners y managers por cuenta.
    <br /> <br />
    Esto, ha sido vital para llevar una correcta administración. Además, la
    funcionalidad de colas dentro de cada cuenta ha ayudado a poder distribuir
    de manera eficiente los casos de atención al cliente, marketing o ventas a
    cada asesor especializado de la marca.
  </span>
)
const text7 = (
  <span>
    Como nos cuenta Liliana, debido a que manejan diferentes tiendas, la marca
    cuenta con un orden en especial para la comunicación con sus clientes, por
    lo que tener integrados WhatsApp, Messenger e Instagram en una sola
    plataforma ha sido vital.
    <br /> <br />
    Algo que ellos consideraban dentro de su proceso de marketing, sin duda, es
    el primer contacto con los leads que llegaban de digital. Esto, ha sido
    potenciado gracias a la potencia que les brinda sus chatbots implementados
    en sus principales canales.
  </span>
)

const text8 = (
  <span>
    “Actualmente, tenemos rapidez y eficiencia para atender a nuestros clientes.
    Esto es vital porque ellos son muy importantes para nosotros. El haber
    disminuido a menos de 1 minutos el tiempo mínimo de atención es un logro
    importante para nosotros.”
  </span>
)
const text9 = (
  <span>
    Como toda operación de marketing digital, el proceso de prospección de leads
    es un punto de suma importancia. Para optimizar el proceso de primer
    contacto, se implementaron chatbots omnicanales.
    <br /> <br />
    Actualmente, Royal Enfield tiene chatbots implementados por diferentes
    rangos horarios en WhatsApp, Facebook Messenger e Instagram. Estos, aparte
    de ayudar con preguntas frecuentes y consultas de sus clientes, están
    configurados para hablar con sus leads, y en base al flujo de conversación,
    ser derivados a colas de agentes de ventas.
    <br /> <br />A palabras de Liliana, la facilidad y eficiencia de los
    chatbots que han implementado autónomamente los ha ayudado a automatizar su
    proceso de marketing, que era uno de los objetivos principales de la
    implementación.
  </span>
)

const text10 = (
  <span>
    “Los chatbots en Beex son una herramienta muy simple de usar y podemos
    editarlos cuando queramos por canal. Ahora tenemos una respuesta inmediata,
    brindándole a nuestros clientes información precisa en nuestra primera
    comunicación. Esto ha ayudado en aumentar en 14% nuestras visitas a nuestros
    locales.”
  </span>
)
const text11 = (
  <span>
    Como siempre, un punto importante en la experiencia brindada por Beex,
    es la de soporte. Nuestro equipo ayudó en las verificaciones de las
    cuentas de Facebook Business desde el primer momento para acceder en tiempo
    record a las APIs de WhatsApp.
    <br /> <br />
    Además, una vez dadas de alta las cuentas en la plataforma, el proceso de
    capacitación fue rápido y eficaz.
    <br /> <br />
    Y no solo eso, como es costumbre, Royal Enfield tiene soporte con Beex
    por diferentes canales, como teléfono, WhatsApp y correo electrónico. Siendo
    capaces de realizar consultas, crear tickets de atención o crear
    requerimientos.
    <br /> <br />
    Como comenta Liliana, esto ha sido un punto esencial en la experiencia:
  </span>
)
const text12 = (
  <span>
    “El servicio de Beex es uno A1. Súper confiable y que ha ayudado mucho
    a la marca. Sentimos que es una atención personalizada y podemos contar con
    ellos de lunes a domingo, lo que es importante para nosotros.”
  </span>
)

const text13 = (
  <p>
    Estos son algunos de los procesos con los que ayudamos 
    a Royal Enfield en su operación de atención al cliente, marketing y
    ventas:
  </p>
)
const text14 = (
  <p>
    En Beex estamos felices de contar con una marca reconocida a nivel
    mundial como Royal Enfield. El poder ayudarlos en sus procesos de
    comunicación y haber logrado sus objetivos iniciales, es parte del trabajo
    contínuo que ambas marcas hemos venido realizando en conjunto.
  </p>
)

const data = {
  intro: {
    image: RoyalEnfield,
    link: "#",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoEnseña,
    text4: text4,
    title5: "Multicuentas",
    text5: text5,
    title7: "Comunicación omnicanal",
    text7: text7,
    text8: text8,
    title9: "Chatbots",
    fototwo: FotoEnseña2,
    text9: text9,
    text10: text10,
    text11: text11,
    title11: "Experiencia de soporte",
    text12: text12,
    text13: text13,
    text14: text14,
    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para sus operaciones de atención al cliente, marketing y ventas. Beex logra que sus clientes conversen con Royal Enfield mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más.",
    },
    {
      title: "Multicuentas",
      img: multicanal,
      info:
        "Royal Enfield cuenta con subcuentas para sus tiendas. Esto, ha hecho que la administración de las comunicaciones de sus diferentes franquicias sea fácil y eficiente. La marca puede diferenciar con facilidad las comunicaciones de los canales por diferentes cuentas.        ",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp, Messenger e Instagram. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Omnicanalidad",
      img: livechat,
      info:
        "Sumado a WhatsApp, Royal Enfield ha integrado Messenger e Instagram a Beex, logrando tener conversaciones por estos canales digitales a través de un solo hilo de conversación omnicanal en una sola ventana de conversación.",
    },
   
  ],
}

export default data
