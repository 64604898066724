/* eslint-disable no-undef */
import React from "react"
import StructureEnseñaPeru from "@components/pageCasosdeExito/template/templatePageRoyalEnfield"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Royal Enfield [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/royal-enfield/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te contamos cómo Royal Enfield administra la comunicación digital de sus tiendas y franquicias con Beex, lo que aumentó sus leads bien prospectados."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Royal Enfield" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/royal-enfield/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-royal.jpg`}
      />
      <meta
        property="og:description"
        content="Te contamos cómo Royal Enfield administra la comunicación digital de sus tiendas y franquicias con Beex, lo que aumentó sus leads bien prospectados."
      />
    </Helmet>
    <StructureEnseñaPeru location={location} />
  </div>
)

export default IndexPage
